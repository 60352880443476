/* CookieConsentComponent.css */

.cookie-consent-bar {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  max-width: 60%;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  opacity: 0.8;
  font-family: "Lato";
  background: #0A252B;
}

.cookie-consent-bar a {
  color: #87D2DB;
}

.cookie-consent-bar > div {
  flex: 1;
  margin-right: 20px;
}

.cookie-consent-bar button {
  margin-left: 10px;
}


